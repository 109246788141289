import React, { Component } from 'react';
import Animation from '../animation';
import portfolioAnimation from "../../static/animations/koepfe.json"

import { Grid } from "../../components/Grid/grid";
import IS_CORPORATE from '../../config/siteConfig';

class KoepfeContent extends Component {

    render() {
        const { data, text, subtitle, title } = this.props;
        console.log('data.edges',data.edges)
        const filteredKoepfe = data && data.edges ? 
            IS_CORPORATE ? data.edges:data.edges.filter(k=>k.node.data.show_on_ventures_page)
        :[];

        return (
            <section id="koepfe">
                <div className="container wide gray">
                    <div className="two-cols">
                        <div className="koepfe__animation">
                            <Animation loop={true} animation={portfolioAnimation} />
                        </div>
                        <div>
                            <h2>{title}</h2>
                            {this.props.subtitle && <h3>{subtitle}</h3>}
                            <div className="detail__text"
                                dangerouslySetInnerHTML={{
                                    __html: text,
                                }}
                            />
                        </div>
                    </div>
                    <div className="koepfe__items">
                        {filteredKoepfe && (
                            <Grid className="grid--koepfe" onlyGrid={this.props.onlyGrid || false} data={filteredKoepfe} />
                        )}
                    </div>
                </div>

            </section>
        );
    }
}

export default KoepfeContent;